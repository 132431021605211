@import '../../styles/variables.scss';

.root {
    text-align: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
