@import '../../styles/variables.scss';

.root {
}

.canvas {
    // width: 100%;
    background-color: black;
    background-size: cover;
}
