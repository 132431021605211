@import '../../styles/variables.scss';

.root {
    position: relative;
    margin: auto;
    width: 90%;
    margin: 30px auto 0 0;
    padding: 20px 20px 75px;
    border: 1px solid $black;
}

.label {
    position: absolute;
    bottom: 0;
    left: 0;
    background: $black;
    color: $white;
    padding: 7.5px 12.5px;
}

.labelRight {
    right: 0;
    left: initial;
}
