// Colours
$white: #ffffff;
$black: #000000;
$grey: #202020;

$primary: #ffa605;
$primary--light: #ffd077;

$padding: 25px;

@mixin buttonStyle {
    padding: 20px;
    width: 125px;
    color: black;
    cursor: pointer;
    border: 1px solid black;
    background-color: lightgrey;
    border-radius: 5px;
}
