@import '../../styles/variables.scss';

.root {
}

.content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: $padding $padding 0;
}

.buttons {
    display: flex;
    justify-content: space-between;
    padding: $padding;
}

.loaderAnimation {
    padding: $padding;
}

.loaderDatabase {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 50px;
}

.loaderDatabaseText {
    padding-bottom: 35px;
}

.loaderDatabaseImage {
    display: block;
    background-color: $white;
    position: relative;
    z-index: 2;
}

.loadingBar {
    position: relative;
    width: 100%;
    border: 1px solid black;
    height: 50px;
    overflow: hidden;
}

.loadingBarInner {
    width: 100%;
    height: 100%;
    background: red;
    transform: translateX(-100%);
}
