@import '../../styles/variables.scss';

.root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: $padding $padding 0;
}

.buttons {
    display: flex;
    justify-content: space-between;
    padding: $padding;
}
