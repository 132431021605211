@import '../../styles/variables.scss';

.root {
    position: relative;
    display: flex;
    justify-content: space-around;

    &:after {
        content: '';
        width: 5px;
        height: 34.5px;
        background: black;
        border: 1px solid green;
        position: absolute;
        top: 0;
        right: 0;
    }

    h2,
    p {
        margin: 0;
    }
}

.barSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-grow: 1;
    border: 1px solid $black;
    border-bottom-width: 2px;
    box-sizing: border-box;
}

.barSectionVariables {
    display: flex;
    gap: 25px;
}

.barVariable {
    padding: 10px 15px;
    text-align: center;

    p {
        margin-bottom: 5px;
        font-size: 22px;
        font-weight: bold;
    }
}

.barSectionNNumber {
    min-width: 282px;
}

.active {
    color: white;
}

@mixin activeVariable {
    border: 1px solid red;
}

.active-0 {
    // background-color: red;

    :global {
        .variable-R {
            @include activeVariable;
        }
    }
}

.active-1 {
    // background-color: green;

    // &:after {
    //     height: 34.5px * 2; // could be used as a progress bar
    // }

    :global {
        .variable-FP {
            @include activeVariable;
        }
    }
}

.active-2 {
    // background-color: blue;

    :global {
        .variable-NE {
            @include activeVariable;
        }
    }
}

.active-3 {
    // background-color: purple;

    :global {
        .variable-FL {
            @include activeVariable;
        }
    }
}

.active-4 {
    // background-color: orange;

    :global {
        .variable-FI {
            @include activeVariable;
        }
    }
}

.active-5 {
    // background-color: yellow;
    :global {
        .variable-FC {
            @include activeVariable;
        }
    }
}

.active-6 {
    // background-color: cyan;
    :global {
        .variable-L {
            @include activeVariable;
        }
    }
}
